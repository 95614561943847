import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import LuncheonTurfway from "/content/assets/resources/luncheon-2023-03-10-turfway.jpg"
import LuncheonSeries from "/content/assets/resources/luncheon-2023-04-18-series.png"
import LuncheonKeeneland from "/content/assets/resources/luncheon-2023-05-16-keeneland.jpg"

const Events = ({ data }) => {
  return (
    <Layout>
      <Helmet>
      <title>Events</title>
    </Helmet>
      <section class="hero is-halfheight hero-banner" id="events-hero">
            <div class="hero-body">
            </div>
      </section>      
      <main>
          <h1>Events</h1>
          <h2>Commonwealth Classic</h2>
          <p>Please check back for more information about the 2023 Commonwealth Classic.</p>
          <br /><br />
          <h2>Equine Educational Luncheon Series</h2>
          <p>The Equine Summit Luncheon Series travels across Central Kentucky to address a wide-variety of topics that are critical to the horse industry.  Each luncheon is $25 for KEEP members, $35 for non-members and $12 for students.</p>      

          <div class="featured-content">
            <span class="featured-logo"><a rel="noreferrer" href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejrzd2cm5dfe3d3f&llr=pjiquldab" target="_blank"><img src={LuncheonKeeneland} alt="Luncheon Series" /></a></span>
            <h3>Equine Educational Luncheon Series</h3>
            <h4>May 16th - Keeneland Library</h4>
            <h4><a rel="noreferrer" href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejrzd2cm5dfe3d3f&llr=pjiquldab" target="_blank">Register Here</a></h4>
          </div>

          <div class="featured-content">
            <span class="featured-logo"><a rel="noreferrer" href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejpr64z207d1294b&llr=pjiquldab" target="_blank"><img src={LuncheonSeries} alt="Luncheon Series" /></a></span>
            <h3>Equine Educational Luncheon Series</h3>
            <h4>April 18th - Second Stride in Oldham County</h4>
            <h4><a rel="noreferrer" href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejpr64z207d1294b&llr=pjiquldab" target="_blank">Register Here</a></h4>
          </div>

          <div class="featured-content">
            <span class="featured-logo"><img src={LuncheonTurfway} alt="Turfway Park" /></span>
            <h3>Equine Educational Luncheon Series</h3>
            <h4>March 10th - Turfway Park</h4>
            <h4><a rel="noreferrer" href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejnd9rpuc62319bd&llr=pjiquldab" target="_blank">Register Here</a></h4>
          </div>  
          <br /><br />
          {/* <iframe title="EventsCalendar" src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&src=Y181YW9nM3YwMTBlNWdiamVsNWdpcWtmbTg4NEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23EF6C00" style={{border: 0, width:"80%", height:"600px",margin:"0, auto", frameborder:"0", scrolling:"no"}}></iframe> */}
      </main>
    </Layout>
  )
}

export default Events

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`